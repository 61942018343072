import API from './API';

export default { 
    getDrivers() {
        return API.get(`/driver?sort=surname,ASC`,{
            params: {
                 join: [
                  'agency'
                ], 
              },
            });
      }, 

      getDriverById(driverId) {
        return API.get(`/driver?filter=id||$eq||${driverId}&`,{
            params: {
                 join: [
                  'agency',
                  'areas'
                ], 
              },
            });
      }, 

      updateDriver(driver) {
        return API.patch('/driver/' + driver.id, driver)
      },

      getPlannersByBookingId(bookingId) {
        return API.get(`/driver/planner/${bookingId}`)
      },

      getDriverByHeadquarterAreaId(areaId) {
        return API.get(`/driver?filter=areas.id||$eq||${areaId}&filter=is_alive||$eq||${false}&filter=is_deleted||$eq||${false}&`,{
            params: {
                 join: [
                  'areas'
                ], 
              },
            });
      }, 

      postDriverAssign(driverAssign) {
        return API.post('/driver/assign', driverAssign)
      },

      postDriver(driver) {
        return API.post('/driver/update', driver)
      },

      postDriverTest(driver) {
        return API.post('/driver/', driver)
      },

      createDriver(driver) {
        return API.post('/auth/register', driver)
      },

      deleteDriver(id) {
        return API.get('/driver/delete/' + id);
      },

      deleteDriverZona(driver) {
        return API.post('/driver/delete-area', driver);
      },
}