<template>
  <div style="margin-top: 40px">
    <h1>Dettagli del booking {{ booking_info.date }}</h1>
    <h2>{{ booking_info.title_prenotazione }}</h2>
  </div>

  <hr
    style="
      height: 4px;
      color: rgb(231, 228, 58);
      background-color: rgb(231, 228, 58);
      width: 35%;
      margin-top: 15px;
      margin-left: 32.5%;
    "
  />

  <br />

  <div
    v-if="type == 5 || type == 7"
    class="d-flex align-items-center"
    style="padding: 20px"
  >
    <el-icon
      :style="{
        fontSize: '34px',
        color: 'orange',
        animation: 'pulse 1s infinite',
      }"
      style="vertical-align: middle"
    >
      <BellFilled />
    </el-icon>
    <span
      v-if="type == 5"
      style="font-weight: bold; vertical-align: middle; margin-left: 15px"
    >
      Il driver ha in dotazione la vostra vettura
    </span>
    <span
      v-if="type == 7"
      style="font-weight: bold; vertical-align: middle; margin-left: 15px"
    >
      Il driver ritira l'auto di cortesia
    </span>
  </div>

  <!--DATA-->
  <el-row justify="center">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Data e ora"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Data Andata
            </div>
          </template>
          {{ date_gone }}
        </el-descriptions-item>
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Clock />
              </el-icon>
              HH:mm
            </div>
          </template>
          {{ hour_gone }}
        </el-descriptions-item>
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Nuova Data Andata
            </div>
          </template>
          <el-date-picker
            v-model="new_date_gone"
            type="date"
            placeholder="Pick a date"
            style="width: 100%"
            format="DD/MM/YYYY"
            @change="handleChangeDateGone"
          />
          <!--<el-button color="rgb(231,228,58)" :dark="true" :disabled="(is_gone && selectedSlotGone==null )" @click="onSubmitDate" style="margin-top:50px">Modifica la prenotazione </el-button>-->
        </el-descriptions-item>
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Clock />
              </el-icon>
              Nuovo orario andata
            </div>
          </template>
          <el-cascader
            v-model="selectedSlotGone"
            placeholder="Ora"
            :options="timeSlotsGone"
            :props="{ value: 'value', label: 'label', disabled: 'disabled' }"
            :disabled="!slotGoneDisabled"
            @change="handleSlotChangeGone"
          />
        </el-descriptions-item>
        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Data Ritorno
            </div>
          </template>
          {{ date_return }}
        </el-descriptions-item>
        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Clock />
              </el-icon>
              HH:mm
            </div>
          </template>
          {{ hour_return }}
        </el-descriptions-item>
        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Nuova data ritorno
            </div>
          </template>
          <el-date-picker
            v-model="new_date_return"
            type="date"
            placeholder="Pick a date"
            style="width: 100%"
            format="DD/MM/YYYY"
            @change="handleChangeDateReturn"
          />
          <!--<VueDatePicker v-model="new_date_return" :enable-time-picker="false" @update:model-value="handleChangeDateReturn" format="dd/MM/yyyy" />-->
        </el-descriptions-item>
        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Clock />
              </el-icon>
              Nuovo orario ritorno
            </div>
          </template>
          <el-cascader
            v-model="selectedSlotReturn"
            placeholder="Ora"
            :options="timeSlotsReturn"
            :props="{ value: 'value', label: 'label', disabled: 'disabled' }"
            :disabled="!slotReturnDisabled"
            @change="handleSlotChangeReturn"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">Stato</div>
          </template>
          <el-select v-model="booking_info.state" placeholder="Select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-button
            type="primary"
            style="margin-left: 30px; vertical-align: center"
            @click="onSubmitBooking(booking_info)"
            >Salva</el-button
          >
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <el-row justify="center">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Cliente"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <user />
              </el-icon>
              Cliente
            </div>
          </template>
          {{ booking_info.customer }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Phone />
              </el-icon>
              Cell. cliente
            </div>
          </template>
          {{ booking_info.customer_number }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="type == 1 || type == 4 || type == 6 || type == 7"
        >
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <Bicycle />
              </el-icon>
              Auto
            </div>
          </template>
          {{ booking_info.auto }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="type == 1 || type == 4 || type == 6 || type == 7"
        >
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <tickets />
              </el-icon>
              Targa
            </div>
          </template>
          {{ booking_info.targa }}
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>
  <!-- PARTNER -->
  <el-row justify="center">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Partner"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <office-building />
              </el-icon>
              Sede del partner
            </div>
          </template>
          {{ booking_info.partner_point }}
        </el-descriptions-item>

        <!-- <el-descriptions-item >
                <template #label>
                <div class="cell-item">
                    <el-icon :style="iconStyle">
                    <LocationInformation />
                    </el-icon>
                    Fascia km andata:
                </div>
                </template>
                {{ booking_info.simba_km_range_gone }}km
            </el-descriptions-item> -->
      </el-descriptions>
    </el-col>
  </el-row>

  <!--PICKUP-->
  <el-row justify="center" v-if="type == 7 || type == 1">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Booking"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Punto di prelievo
            </div>
          </template>
          {{ booking_info.origin_point_gone }}
        </el-descriptions-item>
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Fascia km andata:
            </div>
          </template>
          {{ booking_info.simba_km_range_gone }}km
        </el-descriptions-item>
        <!--<el-descriptions-item v-if="type == 7 && is_gone">
              <template #label>
                  <div class="cell-item">
                  <el-icon :style="iconStyle">
                      <office-building />
                  </el-icon>
                  Sede del partner
                  </div>
              </template>
              {{booking_info.partner_point}}
              </el-descriptions-item>-
              <el-descriptions-item v-if="type == 7 && is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario consegna dell'auto
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>-->
        <!--<el-descriptions-item v-if="type == 7 && is_return">
                  <template #label>
                      <div class="cell-item">
                      <el-icon :style="iconStyle">
                          <office-building />
                      </el-icon>
                      Sede del partner
                      </div>
                  </template>
                  {{booking_info.partner_point}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario ripresa dell'auto
                  </div>
                  </template>
                  {{hour_return}}
              </el-descriptions-item>-->
        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Punto di consegna
            </div>
          </template>
          {{ booking_info.destination_point_return }}
        </el-descriptions-item>
        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Fascia km consegna:
            </div>
          </template>
          {{ booking_info.simba_km_range_return }}km
        </el-descriptions-item>

        <!--<el-descriptions-item v-if="type == 5 && is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di presa del cliente
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>
              <el-descriptions-item v-if="type == 5 && is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di ripresa del cliente
                  </div>
                  </template>
                  {{hour_return}}
              </el-descriptions-item>-->
      </el-descriptions>
    </el-col>
  </el-row>
  <!--ESCORT-->
  <el-row
    justify="center"
    v-if="type == 5 || type == 6 || type == 4 || type == 2"
  >
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Booking"
        :column="2"
        :size="size"
        border
      >
        <!--<el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di prelievo andata
                  </div>
                  </template>
                  {{booking_info.origin_point_gone}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_gone">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di presa del cliente
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>-->
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Punto di consegna andata
            </div>
          </template>
          {{ booking_info.destination_point_gone }}
        </el-descriptions-item>
        <el-descriptions-item v-if="is_gone">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Fascia km andata:
            </div>
          </template>
          {{ booking_info.simba_km_range_gone }}km
        </el-descriptions-item>

        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Punto di prelievo ritorno
            </div>
          </template>
          {{ booking_info.origin_point_return }}
        </el-descriptions-item>
        <!--<el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario di ripresa del cliente
                  </div>
                  </template>
                  {{hour_return}}
              </el-descriptions-item>
              <el-descriptions-item v-if="is_return">
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <LocationInformation />
                      </el-icon>
                      Punto di consegna ritorno
                  </div>
                  </template>
                  {{booking_info.destination_point_return}}
              </el-descriptions-item>-->
        <el-descriptions-item v-if="is_return">
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Fascia km ritorno:
            </div>
          </template>
          {{ booking_info.simba_km_range_return }}km
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <!--CONSEGNA UN AUTO-->
  <el-row justify="center" v-if="type == 3">
    <el-col :span="20">
      <el-descriptions
        class="margin-top"
        title="Booking"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Punto di prelievo
            </div>
          </template>
          {{ booking_info.origin_point_gone }}
        </el-descriptions-item>
        <!--<el-descriptions-item>
                  <template #label>
                  <div class="cell-item">
                      <el-icon :style="iconStyle">
                      <Clock />
                      </el-icon>
                      Orario 
                  </div>
                  </template>
                  {{hour_gone}}
              </el-descriptions-item>-->
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Punto di consegna
            </div>
          </template>
          {{ booking_info.destination_point_gone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              <el-icon :style="iconStyle">
                <LocationInformation />
              </el-icon>
              Fascia km:
            </div>
          </template>
          {{ booking_info.simba_km_range_gone }}km
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <!--DRIVER -->
  <el-row>
    <el-col>
      <h4 style="text-align: left">Dettagli del planner</h4>
      <el-descriptions
        class="margin-top"
        :title="''"
        :column="4"
        border
        v-for="(planner, index) in planners"
        :key="index"
      >
        <el-descriptions-item width="200px">
          <template #label>
            <div class="cell-item">Data inizio</div>
          </template>
          {{ planner.data_inizio }}
        </el-descriptions-item>
        <el-descriptions-item width="200px">
          <template #label>
            <div class="cell-item">Data fine</div>
          </template>
          {{ planner.data_fine }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template #label>
            <div class="cell-item" style="width: 50px">
              {{ planner.type ? 'Driver' : 'Jolly' }}
            </div>
          </template>
          <el-select v-model="planner.driverId" placeholder="Select">
            <el-option
              v-for="item in driverVisible"
              :key="item.id"
              :label="item.name + ' ' + item.surname"
              :value="item.id"
            />
          </el-select>
          <el-button
            type="primary"
            style="margin-left: 30px; vertical-align: center"
            @click="onSubmitDriver(planner.plannerId)"
            >Salva</el-button
          >
        </el-descriptions-item>
      </el-descriptions>
    </el-col>
  </el-row>

  <div justify="center">
    <div style="margin-top: 3%">
      <el-button type="danger" size="large" @click="open"
        >Elimina la prenotazione</el-button
      >
    </div>
  </div>
</template>

<script>
import { ref, toRaw } from 'vue';
import bookingAPI from '../../services/bookingAPI.js';
import { useRoute, useRouter } from 'vue-router';
import { reactive } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import driverAPI from '../../services/driverAPI.js';
import prenotazioneAPI from '@/services/prenotazioneAPI';
const dayjs = require('dayjs');
export default {
  name: 'HomePage',
  components: {},
  setup() {
    //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
    let booking = ref({});
    const ruoloSwitch = ref(true);
    const router = useRouter();
    const type = ref(0);
    let noteVisibile = ref(false);
    let slotGoneDisabled = ref(false);
    let slotReturnDisabled = ref(false);
    const activeName = ref('1');
    const route = useRoute();
    const bookingId = route.params.id.split('-')[0];
    const planners = ref([
      {
        data_fine: '',
        data_inizio: '',
        itinerario: '',
        plannerId: '',
        driverId: '',
        raceId: '',
        type: '',
      },
    ]);
    const plannerToUpd = ref({
      data_fine: '',
      data_inizio: '',
      itinerario: '',
      plannerId: '',
      driverId: '',
      raceId: '',
      type: '',
    });
    const bookingStateToUpd = ref({
      id: '',
      state: '',
    });
    const driverVisible = ref([]);
    const driverSelected = ref('');
    const booking_info = reactive({
      id: '',
      areaId: '',
      customer: '',
      customer_number: '',
      auto: '',
      targa: '',
      tasks: [],
      driver_andata: '',
      driver_ritorno: '',
      telephone_gone_driver: '',
      telephone_return_driver: '',
      title_prenotazione: '',
      title: 'Costo totale dei lavori',
      tot_cost: 0,
      state: '',
      simba_km_range_gone: 0,
      simba_km_range_return: 0,

      origin_point_gone: '',
      destination_point_gone: '',

      origin_point_return: '',
      destination_point_return: '',

      car_description: '',
      note: '',

      loaner: '',
    });

    let date_gone = ref('');
    let new_date_gone = ref(new Date());
    let new_date_return = ref(new Date());
    let hour_gone = ref('');
    let date_return = ref('');
    let hour_return = ref('');
    const options = ref([]);
    let is_gone = ref(false);
    let is_return = ref(false);

    let id_prenotazione_gone = '';
    let id_prenotazione_return = '';

    let driver_gone = null;
    let driver_return = null;

    let origin_point_gone = {};
    let destination_point_gone = {};
    let origin_point_return = {};
    let destination_point_return = {};

    let type_prenotazione_gone = '';
    let type_prenotazione_return = '';
    let area = ref('');

    const state = (state) => {
      switch (state) {
        case 'before':
          state = 'Non ancora accettato';
          break;
        case 'accepted':
          state = 'Accettato dal cliente';
          break;
        case 'startAPickup':
          state = 'Driver in viaggio';
          break;
        case 'driverScanJollyAPickup':
          state = 'Jolly e driver presso la sede';
          break;
        case 'customerAPickup':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoAPickup':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanAPickup':
          state = "Il driver ha ritirato l'auto";
          break;
        case 'partnerAPickup':
          state = 'Driver arrivato in concessionaria';
          break;

        case 'startRPickup':
          state = 'Driver in viaggio';
          break;
        case 'partnerRPickup':
          state = 'Driver arrivato in concessionaria';
          break;
        case 'customerRPickup':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoRPickup':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanRPickup':
          state = "Il driver ha consegnato l'auto";
          break;
        case 'driverScanJollyRPickup':
          state = 'Jolly presso il cliente';
          break;

        case 'startAPickupLoaner':
          state = 'Driver in arrivo';
          break;
        case 'partnerAPickupLoaner':
          state = 'Il Driver ha ritirato auto di cortesia';
          break;
        case 'customerAPickupLoaner':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoAPickupLoaner':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanAPickupLoaner':
          state = "Il cliente ha ritirato l'auto di cortesia";
          break;
        case 'partnersecondAPickupLoaner':
          state = 'Driver arrivato in concessionaria';
          break;

        case 'startRPickupLoaner':
          state = 'Driver in arrivo';
          break;
        case 'partnerRPickupLoaner':
          state = 'Il Driver ha ritirato auto di cortesia';
          break;
        case 'customerRPickupLoaner':
          state = 'Driver arrivato dal cliente';
          break;
        case 'photoRPickupLoaner':
          state = 'Il driver ha fatto le foto';
          break;
        case 'scanRPickupLoaner':
          state = "Il cliente ha ritirato l'auto di cortesia";
          break;

        case 'startADelivery':
          state = 'Driver in viaggio';
          break;
        case 'partnerADelivery':
          state = "Il driver ha ritirato l'auto";
          break;
        case 'photofirstADelivery':
          state = 'Il driver ha fatto le foto';
          break;
        case 'customerADelivery':
          state = 'Driver arrivato al punto di consegna';
          break;
        case 'photosecondADelivery':
          state = "Il driver ha consegnato l'auto";
          break;
        case 'driverScanJollyADelivery':
          state = 'Il jolly ha accompagnato il driver ';
          break;

        case 'startRDelivery':
          state = 'Driver in viaggio';
          break;
        case 'customerRDelivery':
          state = "Il driver ha ritirato l'auto";
          break;
        case 'photofirstRDelivery':
          state = 'Il driver ha fatto le foto';
          break;
        case 'partnerRDelivery':
          state = 'Driver arrivato al punto di consegna';
          break;
        case 'photosecondRDelivery':
          state = "Il driver ha consegnato l'auto";
          break;
        case 'driverScanJollyRDelivery':
          state = 'Il jolly ha accompagnato il driver ';
          break;

        case 'startAEscort':
          state = 'Driver in viaggio';
          break;
        case 'customerAEscort':
          state = 'Driver arrivato al punto di incontro con il cliente';
          break;
        case 'scanfirstAEscort':
          state = 'Il driver ha fatto salire il cliente in auto';
          break;
        case 'scanssecondAEscort':
          state = 'Il driver ha fatto portato il cliente al punto';
          break;

        case 'startREscort':
          state = 'Driver in viaggio';
          break;
        case 'customerREscort':
          state = 'Driver arrivato al punto di incontro con il cliente';
          break;
        case 'scanfirstREscort':
          state = 'Il driver ha fatto salire il cliente in auto';
          break;
        case 'scansecondREscort':
          state = 'Il driver ha fatto portato il cliente al punto';
          break;

        case 'end':
          state = 'Concluso';
          break;
      }
      return state;
    };
    const loadBooking = async () => {
      try {
        const response = await bookingAPI.getBookingById(route.params.id);
        booking.value = response.data;
        area.value = response.data.headquarter.area;

        booking_info.id = booking.value.id;
        booking_info.areaId = booking.value.headquarter.area.id;
        booking_info.date = dayjs(booking.value.datePartner_gone).format(
          'DD/MM/YYYY'
        );
        booking_info.customer =
          booking.value.customer.name + ' ' + booking.value.customer.surname;
        booking_info.customer_number = booking.value.customer.telephone_number;
        booking_info.state = state(booking.value.state);
        booking_info.loaner = booking.value.loaner;

        if (booking.value.note != '') {
          const note = booking.value.note.split(',');
          if (note.length != 0) {
            booking_info.car_description =
              note[0] + ', ' + note[1] + ', ' + note[2] + ', ' + note[3];
            if (note.length == 5) {
              noteVisibile.value = true;
              booking_info.note = note[4].split(':')[1];
            }
          }
        }

        booking_info.partner_point =
          booking.value.headquarter.address +
          ' ' +
          booking.value.headquarter.street_number +
          ', ' +
          booking.value.headquarter.cap +
          ' ' +
          booking.value.headquarter.city +
          ' ' +
          booking.value.headquarter.province;

        if (
          booking.value.pickup_bookings &&
          booking.value.pickup_bookings.length > 0
        ) {
          //loadOptionsState(1);
          booking_info.title_prenotazione = 'Pickup e Delivery';
          booking_info.auto =
            booking.value.car.marca + ', ' + booking.value.car.modello;
          booking_info.targa = booking.value.car.targa;
          booking_info.tasks = booking.value.tasks;

          booking.value.loaner == true ? (type.value = 7) : (type.value = 1);

          booking.value.tasks.forEach((task) => {
            booking_info.tot_cost = booking_info.tot_cost + task.cost;
          });

          for (let i = 0; i < booking.value.pickup_bookings.length; i++) {
            const currentBooking = booking.value.pickup_bookings[i];

            if (currentBooking.type_booking === 'gone') {
              extractBookingDetail(
                currentBooking.date_partner,
                true,
                currentBooking.id,
                currentBooking.driver_pickup,
                booking.value.headquarter,
                currentBooking.point,
                !booking.value.loaner ? 'PICKUP_GONE' : 'PICKUP_AUTO_GONE'
              );
              date_gone.value = dayjs(currentBooking.date_partner).format(
                'DD/MM/YYYY'
              );
              hour_gone.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_gone.value = true;

              booking_info.simba_km_range_gone = currentBooking.simba_km_range;

              booking_info.origin_point_gone =
                currentBooking.point.address +
                ' ' +
                currentBooking.point.street_number +
                ', ' +
                currentBooking.point.cap +
                ' ' +
                currentBooking.point.city +
                ' ' +
                currentBooking.point.province;

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.driver_andata =
                    currentBooking.driver_pickup.name +
                    ' ' +
                    currentBooking.driver_pickup.surname)
                : 'Non assegnato';

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.telephone_gone_driver =
                    currentBooking.driver_pickup.telephone_number)
                : null;
            } else {
              extractBookingDetail(
                currentBooking.date_partner,
                false,
                currentBooking.id,
                currentBooking.driver_pickup,
                booking.value.headquarter,
                currentBooking.point,
                !booking.value.loaner ? 'PICKUP_RETURN' : 'PIKCUP_AUTO_RETURN'
              );
              date_return.value = dayjs(currentBooking.date_partner).format(
                'DD/MM/YYYY'
              );
              hour_return.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_return.value = true;

              booking_info.simba_km_range_return =
                currentBooking.simba_km_range;

              booking_info.destination_point_return =
                currentBooking.point.address +
                ' ' +
                currentBooking.point.street_number +
                ', ' +
                currentBooking.point.cap +
                ' ' +
                currentBooking.point.city +
                ' ' +
                currentBooking.point.province;

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.driver_ritorno =
                    currentBooking.driver_pickup.name +
                    ' ' +
                    currentBooking.driver_pickup.surname)
                : 'Non assegnato';

              currentBooking.driver_pickup != null &&
              currentBooking.driver_pickup.is_alive == false
                ? (booking_info.telephone_return_driver =
                    currentBooking.driver_pickup.telephone_number)
                : null;
            }
          }
        }

        if (
          booking.value.escort_bookings &&
          booking.value.escort_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.escort_bookings.length; i++) {
            const currentBooking = booking.value.escort_bookings[i];

            booking_info.title_prenotazione = 'Accompagna un cliente';

            if (currentBooking.tasks == true && booking.value.loaner == true) {
              //booking_info.auto = booking.car.marca + ", " + booking.car.modello;
              //booking_info.targa = booking.car.targa;
              type.value = 6;
            } else if (
              currentBooking.tasks == false &&
              booking.value.loaner == true
            ) {
              type.value = 5;
            } else if (
              currentBooking.tasks == true &&
              booking.value.loaner == false
            ) {
              //booking_info.auto = booking.car.marca + ", " + booking.car.modello;
              //booking_info.targa = booking.car.targa;
              type.value = 4;
            } else {
              type.value = 2;
            }
            if (currentBooking.type_booking === 'gone') {
              extractBookingDetail(
                currentBooking.date_partner,
                true,
                currentBooking.id,
                currentBooking.driver_escort,
                currentBooking.point_origin_escort,
                currentBooking.point_destination_escort,
                !booking.value.loaner ? 'ESCORT_GONE' : 'ESCORT_AUTO_GONE'
              );
              date_gone.value = dayjs(currentBooking.date_partner).format(
                'DD/MM/YYYY'
              );
              hour_gone.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_gone.value = true;

              booking_info.simba_km_range_gone = currentBooking.simba_km_range;

              booking_info.origin_point_gone =
                currentBooking.point_origin_escort.address +
                ' ' +
                currentBooking.point_origin_escort.street_number +
                ', ' +
                currentBooking.point_origin_escort.cap +
                ' ' +
                currentBooking.point_origin_escort.city +
                ' ' +
                currentBooking.point_origin_escort.province;

              booking_info.destination_point_gone =
                currentBooking.point_destination_escort.address +
                ' ' +
                currentBooking.point_destination_escort.street_number +
                ', ' +
                currentBooking.point_destination_escort.cap +
                ' ' +
                currentBooking.point_destination_escort.city +
                ' ' +
                currentBooking.point_destination_escort.province;

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.driver_andata =
                    currentBooking.driver_escort.name +
                    ' ' +
                    currentBooking.driver_escort.surname)
                : 'Non assegnato';

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.telephone_gone_driver =
                    currentBooking.driver_escort.telephone_number)
                : null;
            } else {
              extractBookingDetail(
                currentBooking.date_partner,
                false,
                currentBooking.id,
                currentBooking.driver_escort,
                currentBooking.point_origin_escort,
                currentBooking.point_destination_escort,
                !booking.value.loaner ? 'ESCORT_RETURN' : 'ESCORT_AUTO_RETURN'
              );
              date_return.value = dayjs(currentBooking.date_partner).format(
                'DD/MM/YYYY'
              );
              hour_return.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_return.value = true;
              booking_info.simba_km_range_return =
                currentBooking.simba_km_range;

              booking_info.origin_point_return =
                currentBooking.point_origin_escort.address +
                ' ' +
                currentBooking.point_origin_escort.street_number +
                ', ' +
                currentBooking.point_origin_escort.cap +
                ' ' +
                currentBooking.point_origin_escort.city +
                ' ' +
                currentBooking.point_origin_escort.province;

              booking_info.destination_point_return =
                currentBooking.point_destination_escort.address +
                ' ' +
                currentBooking.point_destination_escort.street_number +
                ', ' +
                currentBooking.point_destination_escort.cap +
                ' ' +
                currentBooking.point_destination_escort.city +
                ' ' +
                currentBooking.point_destination_escort.province;

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.driver_ritorno =
                    currentBooking.driver_escort.name +
                    ' ' +
                    currentBooking.driver_escort.surname)
                : 'Non assegnato';

              currentBooking.driver_escort != null &&
              currentBooking.driver_escort.is_alive == false
                ? (booking_info.telephone_return_driver =
                    currentBooking.driver_escort.telephone_number)
                : null;
            }
          }
        }
        if (
          booking.value.delivery_bookings &&
          booking.value.delivery_bookings.length > 0
        ) {
          for (let i = 0; i < booking.value.delivery_bookings.length; i++) {
            booking_info.title_prenotazione = "Consegna un'auto";

            type.value = 3;
            const currentBooking = booking.value.delivery_bookings[i];

            if (currentBooking.type_booking === 'gone') {
              extractBookingDetail(
                currentBooking.date_point,
                true,
                currentBooking.id,
                currentBooking.driver_delivery,
                currentBooking.point_origin_delivery,
                currentBooking.point_destination_delivery,
                'DELIVERY_GONE'
              );
              date_gone.value = dayjs(currentBooking.date_point).format(
                'DD/MM/YYYY'
              );
              hour_gone.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_gone.value = true;
              booking_info.simba_km_range_gone = currentBooking.simba_km_range;

              booking_info.origin_point_gone =
                currentBooking.point_origin_delivery.address +
                ' ' +
                currentBooking.point_origin_delivery.street_number +
                ', ' +
                currentBooking.point_origin_delivery.cap +
                ' ' +
                currentBooking.point_origin_delivery.city +
                ' ' +
                currentBooking.point_origin_delivery.province;

              booking_info.destination_point_gone =
                currentBooking.point_destination_delivery.address +
                ' ' +
                currentBooking.point_destination_delivery.street_number +
                ', ' +
                currentBooking.point_destination_delivery.cap +
                ' ' +
                currentBooking.point_destination_delivery.city +
                ' ' +
                currentBooking.point_destination_delivery.province;

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.driver_andata =
                    currentBooking.driver_delivery.name +
                    ' ' +
                    currentBooking.driver_delivery.surname)
                : 'Non assegnato';

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.telephone_gone_driver =
                    currentBooking.driver_delivery.telephone_number)
                : null;
            } else {
              extractBookingDetail(
                currentBooking.date_point,
                false,
                currentBooking.id,
                currentBooking.driver_delivery,
                currentBooking.point_origin_delivery,
                currentBooking.point_destination_delivery,
                'DELIVERY_GONE'
              );
              date_return.value = dayjs(currentBooking.date_point).format(
                'DD/MM/YYYY'
              );
              hour_return.value = dayjs(currentBooking.date_partner).format(
                'HH:mm'
              );
              is_return.value = true;

              booking_info.simba_km_range_return =
                currentBooking.simba_km_range;
              booking_info.origin_point_return =
                currentBooking.point_origin_delivery.address +
                ' ' +
                currentBooking.point_origin_delivery.street_number +
                ', ' +
                currentBooking.point_origin_delivery.cap +
                ' ' +
                currentBooking.point_origin_delivery.city +
                ' ' +
                currentBooking.point_origin_delivery.province;

              booking_info.destination_point_return =
                currentBooking.point_destination_delivery.address +
                ' ' +
                currentBooking.point_destination_delivery.street_number +
                ', ' +
                currentBooking.point_destination_delivery.cap +
                ' ' +
                currentBooking.point_destination_delivery.city +
                ' ' +
                currentBooking.point_destination_delivery.province;

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.driver_ritorno =
                    currentBooking.driver_delivery.name +
                    ' ' +
                    currentBooking.driver_delivery.surname)
                : 'Non assegnato';

              currentBooking.driver_delivery != null &&
              currentBooking.driver_delivery.is_alive == false
                ? (booking_info.telephone_return_driver =
                    currentBooking.driver_delivery.telephone_number)
                : null;
            }
          }
        }
        loadDriversVisible(booking_info.areaId);
        loadOptionsState(type.value, is_gone.value, is_return.value);
        return booking;
      } catch (e) {
        console.log(e);
      }
    };

    loadBooking();

    /*     //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
    const loadOptionsState = (type, is_gone, is_return) => {
      switch ((type, is_gone, is_return)) {
        case type == 1 && is_gone:
          options.value.push(
            {
              value: 'accepted',
              label: 'Accettato',
            },
            {
              value: 'startAPickup',
              label: 'Driver in viaggio',
            },
            {
              value: 'driverShowJollyAPickup',
              label: 'Jolly e driver presso la sede',
            },
            {
              value: 'customerAPickup',
              label: 'Driver arrivato al cliente',
            },
            {
              value: 'photoAPickup',
              label: 'Il driver ha fatto le foto',
            },
            {
              value: 'scanAPickup',
              label: "Il driver ha ritirato l'auto",
            },
            {
              value: 'partnerAPickup',
              label: 'Il driver è arrivato in concessionaria',
            }
          );
          if (is_gone && !is_return) {
            options.value.push({
              value: 'end',
              label: 'Concluso',
            });
          }

          if (is_return) {
            if (is_gone) {
              options.value.push({
                value: 'partnerAPickup',
                label: 'Driver in viaggio',
              });
            } else {
              options.value.push({
                value: 'accepted',
                label: 'Accettato',
              });
            }
            options.value.push(
              {
                value: 'startRPickup',
                label: 'Driver in viaggio',
              },
              {
                value: 'partnerRPickup',
                label: 'Driver arrivato in concessionaria',
              },
              {
                value: 'customerRPickup',
                label: 'Driver arrivato dal cliente',
              },
              {
                value: 'photoRPickup',
                label: 'Il driver ha fatto le foto',
              },
              {
                value: 'scanRPickup',
                label: "Il driver ha consegnato l'auto",
              },
              {
                value: 'driverShowJollyRPickup',
                label: 'Jolly presso il cliente',
              },
              {
                value: 'end',
                label: 'Concluso',
              }
            );
          }
          break;
        case type == 7 && is_gone:
          options.value.push(
            {
              value: 'accepted',
              label: 'Accettato',
            },
            {
              value: 'startAPickupLoaner',
              label: 'Driver in arrivo',
            },
            {
              value: 'partnerAPickupLoaner',
              label: 'Il driver ha ritirato auto di cortesia',
            },
            {
              value: 'customerAPickupLoaner',
              label: 'Driver arrivato dal cliente',
            },
            {
              value: 'photoAPickupLoaner',
              label: 'Il driver ha fatto le foto',
            },
            {
              value: 'scanAPickupLoaner',
              label: "Driver ha ritirato l'auto di cortesia",
            },
            {
              value: 'partnersecondAPickupLoaner',
              label: 'Driver arrivato in concessionaria',
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          if (is_return) {
            if (is_gone) {
              options.value.push({
                value: 'partnersecondAPickupLoaner',
                label: 'Driver arrivato in concessionaria',
              });
            } else {
              options.value.push({
                value: 'accepted',
                label: 'Accettato',
              });
            }
            options.value.push(
              {
                value: 'startRPickupLoaner',
                label: 'Driver in arrivo',
              },
              {
                value: 'partnerRPickupLoaner',
                label: 'Il driver ha ritirato auto di cortesia',
              },
              {
                value: 'customerRPickupLoaner',
                label: 'Driver arrivato dal cliente',
              },
              {
                value: 'photoRPickupLoaner',
                label: 'Il driver ha fatto le foto',
              },
              {
                value: 'scanRPickupLoaner',
                label: "Driver ha ritirato l'auto di cortesia",
              },
              {
                value: 'end',
                label: 'Concluso',
              }
            );
          }
          break;
        case type == 3 && is_gone:
          options.value.push(
            {
              value: 'accepted',
              label: 'Accettato',
            },
            {
              value: 'startADelivery',
              label: 'Driver in viaggio',
            },
            {
              value: 'partnerADelivery',
              label: "Driver ha ritirato l'auto",
            },
            {
              value: 'photofirstADelivery',
              label: 'Il driver ha fatto le foto',
            },
            {
              value: 'customerADelivery',
              label: 'Driver è arrivato al punto di consegna',
            },
            {
              value: 'photosecondADelivery',
              label: "Driver ha consegnato l'auto",
            },
            {
              value: 'driverShowJollyADelivery',
              label: 'Il jolly ha accompagnato il driver',
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          if (is_return) {
            if (is_gone) {
              options.value.push({
                value: 'driverShowJollyADelivery',
                label: 'Il jolly ha accompagnato il driver',
              });
            } else {
              options.value.push({
                value: 'accepted',
                label: 'Accettato',
              });
            }
            options.value.push(
              {
                value: 'startRDelivery',
                label: 'Driver in viaggio',
              },
              {
                value: 'customerRDelivery',
                label: "Driver ha ritirato l'auto",
              },
              {
                value: 'photofirstRDelivery',
                label: 'Il driver ha fatto le foto',
              },
              {
                value: 'partnerRDelivery',
                label: 'Driver è arrivato al punto di consegna',
              },
              {
                value: 'photosecondRDelivery',
                label: "Driver ha consegnato l'auto",
              },
              {
                value: 'end',
                label: 'Concluso',
              }
            );
          }
          break;
        case (type == 2 || type == 4) && is_gone:
          options.value.push(
            {
              value: 'accepted',
              label: 'Accettato',
            },
            {
              value: 'startAEscort',
              label: 'Driver in viaggio',
            },
            {
              value: 'customerAEscort',
              label: 'Driver è arrivato al pumto di incontro con il cliente',
            },
            {
              value: 'scanfirstAEscort',
              label: 'Il driver ha fatto salire il cliente in auto',
            },
            {
              value: 'scanssecondAEscort',
              label: 'Il driver ha portato il cliente al punto stabilito',
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          if (is_return) {
            if (is_gone) {
              options.value.push({
                value: 'scanssecondAEscort',
                label: 'Il driver ha portato il cliente al punto stabilito',
              });
            } else {
              options.value.push({
                value: 'accepted',
                label: 'Accettato',
              });
            }
            options.value.push(
              {
                value: 'startREscort',
                label: 'Driver in viaggio',
              },
              {
                value: 'customerREscort',
                label: 'Driver è arrivato al punto di incontro con il cliente',
              },
              {
                value: 'scanfirstREscort',
                label: 'Il driver ha fatto salire il cliente in auto',
              },
              {
                value: 'scanssecondREscort',
                label: 'Il driver ha portato il cliente al punto stabilito',
              },
              {
                value: 'end',
                label: 'Concluso',
              }
            );
          }
          break;

        case (type == 5 || type == 6) && is_gone:
          options.value.push(
            {
              value: 'accepted',
              label: 'Accettato',
            },
            {
              value: 'startAEscort',
              label: 'Driver in viaggio',
            },
            {
              value: 'customerAEscort',
              label: 'Driver è arrivato al pumto di incontro con il cliente',
            },
            {
              value: 'scanfirstAEscort',
              label: 'Il driver ha fatto salire il cliente in auto',
            },
            {
              value: 'scanssecondAEscort',
              label: 'Il driver ha portato il cliente al punto stabilito',
            },
            {
              value: 'partnerAEscort',
              label: "Il driver ha consegnato l'auto al partner",
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          if (is_return) {
            if (is_gone) {
              options.value.push({
                value: 'partnerAEscort',
                label: "Il driver ha consegnato l'auto al partner",
              });
            } else {
              options.value.push({
                value: 'accepted',
                label: 'Accettato',
              });
            }
            options.value.push(
              {
                value: 'startREscort',
                label: 'Driver in viaggio',
              },
              {
                value: 'customerREscort',
                label: 'Driver è arrivato al punto di incontro con il cliente',
              },
              {
                value: 'scanfirstREscort',
                label: 'Il driver ha fatto salire il cliente in auto',
              },
              {
                value: 'scanssecondREscort',
                label: 'Il driver ha portato il cliente al punto stabilito',
              },
              {
                value: 'end',
                label: 'Concluso',
              }
            );
          }
          break;

        case type == 1 && is_return:
          if (is_gone) {
            options.value.push({
              value: 'partnerAPickup',
              label: 'Driver in viaggio',
            });
          } else {
            options.value.push({
              value: 'accepted',
              label: 'Accettato',
            });
          }
          options.value.push(
            {
              value: 'startRPickup',
              label: 'Driver in viaggio',
            },
            {
              value: 'partnerRPickup',
              label: 'Driver arrivato in concessionaria',
            },
            {
              value: 'customerRPickup',
              label: 'Driver arrivato dal cliente',
            },
            {
              value: 'photoRPickup',
              label: 'Il driver ha fatto le foto',
            },
            {
              value: 'scanRPickup',
              label: "Il driver ha consegnato l'auto",
            },
            {
              value: 'driverShowJollyRPickup',
              label: 'Jolly presso il cliente',
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          break;
        case type == 7 && is_return:
          if (is_gone) {
            options.value.push({
              value: 'partnersecondAPickupLoaner',
              label: 'Driver arrivato in concessionaria',
            });
          } else {
            options.value.push({
              value: 'accepted',
              label: 'Accettato',
            });
          }
          options.value.push(
            {
              value: 'startRPickupLoaner',
              label: 'Driver in arrivo',
            },
            {
              value: 'partnerRPickupLoaner',
              label: 'Il driver ha ritirato auto di cortesia',
            },
            {
              value: 'customerRPickupLoaner',
              label: 'Driver arrivato dal cliente',
            },
            {
              value: 'photoRPickupLoaner',
              label: 'Il driver ha fatto le foto',
            },
            {
              value: 'scanRPickupLoaner',
              label: "Driver ha ritirato l'auto di cortesia",
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          break;
        case type == 3 && is_return:
          if (is_gone) {
            options.value.push({
              value: 'driverShowJollyADelivery',
              label: 'Il jolly ha accompagnato il driver',
            });
          } else {
            options.value.push({
              value: 'accepted',
              label: 'Accettato',
            });
          }
          options.value.push(
            {
              value: 'startRDelivery',
              label: 'Driver in viaggio',
            },
            {
              value: 'customerRDelivery',
              label: "Driver ha ritirato l'auto",
            },
            {
              value: 'photofirstRDelivery',
              label: 'Il driver ha fatto le foto',
            },
            {
              value: 'partnerRDelivery',
              label: 'Driver è arrivato al punto di consegna',
            },
            {
              value: 'photosecondRDelivery',
              label: "Driver ha consegnato l'auto",
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          break;
        case (type == 2 || type == 4) && is_return:
          if (is_gone) {
            options.value.push({
              value: 'scanssecondAEscort',
              label: 'Il driver ha portato il cliente al punto stabilito',
            });
          } else {
            options.value.push({
              value: 'accepted',
              label: 'Accettato',
            });
          }
          options.value.push(
            {
              value: 'startREscort',
              label: 'Driver in viaggio',
            },
            {
              value: 'customerREscort',
              label: 'Driver è arrivato al punto di incontro con il cliente',
            },
            {
              value: 'scanfirstREscort',
              label: 'Il driver ha fatto salire il cliente in auto',
            },
            {
              value: 'scanssecondREscort',
              label: 'Il driver ha portato il cliente al punto stabilito',
            }
          );
          break;
        case (type == 5 || type == 6) && is_return:
          if (is_gone) {
            options.value.push({
              value: 'partnerAEscort',
              label: "Il driver ha consegnato l'auto al partner",
            });
          } else {
            options.value.push({
              value: 'accepted',
              label: 'Accettato',
            });
          }
          options.value.push(
            {
              value: 'startREscort',
              label: 'Driver in viaggio',
            },
            {
              value: 'customerREscort',
              label: 'Driver è arrivato al punto di incontro con il cliente',
            },
            {
              value: 'scanfirstREscort',
              label: 'Il driver ha fatto salire il cliente in auto',
            },
            {
              value: 'scanssecondREscort',
              label: 'Il driver ha portato il cliente al punto stabilito',
            },
            {
              value: 'end',
              label: 'Concluso',
            }
          );
          break;
      }
      return options;
    }; */

   /*  const loadOptionsState = (type, is_gone, is_return) => {
      const addOptions = (optionsArray) => {
        options.value.push(...optionsArray);
      };

      const commonOptions = () => [
        {
          value: 'accepted',
          label: 'Accettato',
        },
        {
          value: 'end',
          label: 'Concluso',
        },
      ];

      const goneOptions = (suffix) => [
        { value: `startA${suffix}`, label: 'Driver in viaggio Andata' },
        {
          value: `driverShowJollyA${suffix}`,
          label: 'Scansione Jolly e Driver',
        },
       
        { value: `customerA${suffix}`, label: `Driver arrivato dal cliente` },
        { value: `photoA${suffix}`, label: 'Il driver ha fatto le foto' },
        { value: `scanA${suffix}`, label: `Il driver ha ritirato l'auto` },
        {
          value: `partnerA${suffix}`,
          label: `Driver arrivato in concessionaria`,
        },
      ];

      const returnOptions = (suffix) => [
        { value: `startR${suffix}`, label: 'Driver in viaggio Ritorno' },
        {
          value: `partnerR${suffix}`,
          label: `Driver arrivato in concessionaria`,
        },
        { value: `customerR${suffix}`, label: `Driver arrivato dal cliente` },
        { value: `photoR${suffix}`, label: 'Il driver ha fatto le foto' },
        { value: `scanR${suffix}`, label: `Driver ha consegnato l'auto` },
        {
          value: `driverShowJollyR${suffix}`,
          label: 'Scansione Jolly e Driver',
        },
      ];

      if (is_gone) {
        switch (type) {
          case 1:
            addOptions([...commonOptions(), ...goneOptions('Pickup')]);
            break;
          case 7:
            addOptions([...commonOptions(), ...goneOptions('PickupLoaner')]);
            break;
          case 3:
            addOptions([...commonOptions(), ...goneOptions('Delivery')]);
            break;
          case 2:
          case 4:
          case 5:
          case 6:
            addOptions([...commonOptions(), ...goneOptions('Escort')]);
            break;
          default:
            break;
        }
      }

      if (is_return) {
        switch (type) {
          case 1:
            addOptions([...returnOptions('Pickup'), ...commonOptions()]);
            break;
          case 7:
            addOptions([...returnOptions('PickupLoaner'), ...commonOptions()]);
            break;
          case 3:
            addOptions([...returnOptions('Delivery'), ...commonOptions()]);
            break;
          case 2:
          case 4:
          case 5:
          case 6:
            addOptions([...returnOptions('Escort'), ...commonOptions()]);
            break;
          default:
            break;
        }
      }

      return options;
    };
 */

 const loadOptionsState = (type, is_gone, is_return) => {
  // Function to add options
  const addOptions = (optionsArray) => {
    options.value.push(...optionsArray);
  };

  // Common options for all types
  const commonOptions = [
    { value: 'accepted', label: 'Accettato' }
  ];

  // Define options based on type
  //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
  const optionsMap = {
    1: {
      gone: [
        ...commonOptions,
        { value: 'startAPickup', label: 'A - Driver in viaggio' },
        { value: 'driverShowJollyAPickup', label: 'A - Jolly e driver presso la sede' },
        { value: 'customerAPickup', label: 'A - Driver arrivato al cliente' },
        { value: 'photoAPickup', label: 'A - Il driver ha fatto le foto' },
        { value: 'scanAPickup', label: "A - Il driver ha ritirato l'auto" },
        { value: 'partnerAPickup', label: 'A - Il driver è arrivato in concessionaria' },
        
      ],
      return: [
        { value: 'startRPickup', label: 'R - Driver in viaggio' },
        { value: 'partnerRPickup', label: 'R - Driver arrivato in concessionaria' },
        { value: 'customerRPickup', label: 'R - Driver arrivato dal cliente' },
        { value: 'photoRPickup', label: 'R - Il driver ha fatto le foto' },
        { value: 'scanRPickup', label: "R - Il driver ha consegnato l'auto" },
        { value: 'driverShowJollyRPickup', label: 'R - Jolly presso il cliente' },
        
      ]
    },
    2: {
      gone: [
        ...commonOptions,
        { value: 'startAEscort', label: 'A - Driver in viaggio' },
        { value: 'customerAEscort', label: 'A - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstAEscort', label: 'A - Il driver ha fatto salire il cliente in auto' },
        { value: 'scanssecondAEscort', label: 'A - Il driver ha portato il cliente al punto stabilito' },
        { value: 'partnerAEscort', label: 'A - Il driver è arrivato in concessionaria per consegnare auto' },
        
      ],
      return: [
        { value: 'startREscort', label: 'R - Driver in viaggio' },
        { value: 'customerREscort', label: 'R - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstREscort', label: 'R - Il driver ha fatto salire il cliente in auto' },
        { value: 'scansecondREscort', label: 'R - Il driver ha portato il cliente al punto stabilito' },
        
      ]
    },
    4: {
      gone: [
        ...commonOptions,
        { value: 'startAEscort', label: 'A - Driver in viaggio' },
        { value: 'customerAEscort', label: 'A - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstAEscort', label: 'A - Il driver ha fatto salire il cliente in auto' },
        { value: 'scanssecondAEscort', label: 'A - Il driver ha portato il cliente al punto stabilito' },
        { value: 'partnerAEscort', label: 'A - Il driver è arrivato in concessionaria per consegnare auto' },
        
      ],
      return: [
        { value: 'startREscort', label: 'R - Driver in viaggio' },
        { value: 'customerREscort', label: 'R - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstREscort', label: 'R - Il driver ha fatto salire il cliente in auto' },
        { value: 'scansecondREscort', label: 'R - Il driver ha portato il cliente al punto stabilito' },
        
      ]
    },
    5: {
      gone: [
        ...commonOptions,
        { value: 'startAEscort', label: 'A - Driver in viaggio' },
        { value: 'customerAEscort', label: 'A - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstAEscort', label: 'A - Il driver ha fatto salire il cliente in auto' },
        { value: 'scanssecondAEscort', label: 'A - Il driver ha portato il cliente al punto stabilito' },
        { value: 'partnerAEscort', label: 'A - Il driver è arrivato in concessionaria per consegnare auto' },
        
      ],
      return: [
        { value: 'startREscort', label: 'R - Driver in viaggio' },
        { value: 'customerREscort', label: 'R - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstREscort', label: 'R - Il driver ha fatto salire il cliente in auto' },
        { value: 'scansecondREscort', label: 'R - Il driver ha portato il cliente al punto stabilito' },
        
      ]
    },
    6: {
      gone: [
        ...commonOptions,
        { value: 'startAEscort', label: 'A - Driver in viaggio' },
        { value: 'customerAEscort', label: 'A - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstAEscort', label: 'A - Il driver ha fatto salire il cliente in auto' },
        { value: 'scanssecondAEscort', label: 'A - Il driver ha portato il cliente al punto stabilito' },
        { value: 'partnerAEscort', label: 'A - Il driver è arrivato in concessionaria per consegnare auto' },
        
      ],
      return: [
        { value: 'startREscort', label: 'R - Driver in viaggio' },
        { value: 'customerREscort', label: 'R - Driver è arrivato al punto di incontro con il cliente'},
        { value: 'scanfirstREscort', label: 'R - Il driver ha fatto salire il cliente in auto' },
        { value: 'scansecondREscort', label: 'R - Il driver ha portato il cliente al punto stabilito' },
        
      ]
    },


    3: {
      gone: [
        ...commonOptions,
        { value: 'startADelivery', label: 'A - Driver in viaggio' },
        { value: 'partnerADelivery', label: "A - Driver ha ritirato l'auto" },
        { value: 'photofirstADelivery', label: 'A - Il driver ha fatto le foto' },
        { value: 'customerADelivery', label: 'A - Driver è arrivato al punto di consegna' },
        { value: 'photosecondADelivery', label: "A - Driver ha consegnato l'auto" },
        { value: 'driverShowJollyADelivery', label: 'A - Il jolly ha accompagnato il driver' },
       
      ],
      return: [
        { value: 'startRDelivery', label: 'R - Driver in viaggio' },
        { value: 'customerRDelivery', label: "R - Driver ha ritirato l'auto" },
        { value: 'photofirstRDelivery', label: 'R - Il driver ha fatto le foto' },
        { value: 'partnerRDelivery', label: 'R - Driver è arrivato al punto di consegna' },
        { value: 'photosecondRDelivery', label: "R - Driver ha consegnato l'auto" },
       
      ]
    },

    7: {
      gone: [
        ...commonOptions,
        { value: 'startAPickupLoaner', label: 'A - Driver in viaggio' },
        { value: 'partnerAPickupLoaner', label: 'A - Driver ritira auto di cortesia' },
        { value: 'customerAPickupLoaner', label: 'A - Driver arrivato al cliente' },
        { value: 'photoAPickupLoaner', label: 'A - Il driver ha fatto le foto' },
        { value: 'scanAPickupLoaner', label: "A - Il driver ha ritirato l'auto" },
        { value: 'partnersecondAPickupLoaner', label: ' A - Il driver è arrivato in concessionaria' },
        
      ],
      return: [
        { value: 'startRPickupLoaner', label: 'R - Driver in viaggio' },
        { value: 'partnerRPickupLoaner', label: 'R - Driver arrivato in concessionaria' },
        { value: 'customerRPickupLoaner', label: 'R - Driver arrivato dal cliente' },
        { value: 'photoRPickupLoaner', label: 'R - Il driver ha fatto le foto' },
        { value: 'scanRPickupLoaner', label: "R - Il driver ha consegnato l'auto" },
        
      ]
    },
   

  };

  if (!is_gone) {
    options.value.unshift(...commonOptions);
  }

  // Determine if gone or return options should be added based on the type
  if (optionsMap[type]) {
    if (is_gone) {
      addOptions(optionsMap[type].gone);
      // Additional logic for is_return if needed
    }
    if (is_return) {
      addOptions(optionsMap[type].return);
    }

    options.value.push( { value: 'end', label: 'Concluso' })
  }

  return options;
};

    const loadPlanners = async (bookingId) => {
      try {
        planners.value = [];
        const response = await driverAPI.getPlannersByBookingId(bookingId);
        const plan = response.data;

        plan.forEach((planner) => {
          let tableRow = {
            data_fine: planner.data_fine,
            data_inizio: planner.data_inizio,
            itinerario: planner.itinerario,
            plannerId: planner.plannerId,
            raceId: planner.raceId,
            type: planner.type == 'driver' ? true : false,
          };
          planner.driver.is_alive == true
            ? (tableRow.driverId = '')
            : (tableRow.driverId = planner.driver.id),
            planners.value.push(tableRow);
        });
        return planners;
      } catch (e) {
        console.log(e);
      }
    };

    loadPlanners(route.params.id);

    const loadDriversVisible = async (areaId) => {
      try {
        driverVisible.value = [];
        const response = await driverAPI.getDriverByHeadquarterAreaId(areaId);
        const driver = response.data;

        driver.forEach((d) => {
          driverVisible.value.push(d);
        });
        return driverVisible;
      } catch (e) {
        console.log(e);
      }
    };

    const open = async () => {
      ElMessageBox.confirm(
        'Sei sicuro di voler eliminare la prenotazione?',
        'Warning',
        {
          confirmButtonText: 'Elimina',
          cancelButtonText: 'Annulla',
          type: 'warning',
        }
      )
        .then(async () => {
          await bookingAPI.deleteBooking(route.params.id);
          ElMessage({
            type: 'success',
            message: 'Eliminazione completata',
          });
          router.push('/');
        })
        .catch(() => {
          /*ElMessage({
              type: "info",
              message: "Annulla",
            });*/
        });
    };

    const postDriverAssign = async (driver) => {
      let response = {};
      try {
        response = await driverAPI.postDriverAssign(driver);
        return response;
      } catch {
        console.log('error');
      }
    };

    const patchBookingState = async (booking) => {
      let response = {};
      try {
        if (booking.state == 'accepted') {
          response = await bookingAPI.patchBooking(booking);
          if (response.status == 201) {
            response = await bookingAPI.acceptBooking(booking);
          }
        } else {
          response = await bookingAPI.patchBooking(booking);
        }
        return response;
      } catch {
        console.log('error');
      }
    };

    const selectedSlotGone = ref(null);
    const selectedSlotReturn = ref(null);
    const timeSlotsGone = ref([
      {
        value: '08:00',
        label: '08:00',
      },
      {
        value: '08:15',
        label: '08:15',
      },
      {
        value: '08:30',
        label: '08:30',
      },
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);
    const timeSlotsReturn = ref([
      {
        value: '08:00',
        label: '08:00',
      },
      {
        value: '08:15',
        label: '08:15',
      },
      {
        value: '08:30',
        label: '08:30',
      },
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);

    const handleChangeDateGone = async () => {
      try {
        const selectedDate = dayjs(new_date_gone.value).format('YYYY-MM-DD');
        await loadSlotGone(selectedDate);
        slotGoneDisabled.value = true;
      } catch (error) {
        console.error(error);
      }
    };

    const handleChangeDateReturn = async () => {
      try {
        const selectedDate = dayjs(new_date_return.value).format('YYYY-MM-DD');
        await loadSlotReturn(selectedDate);
        slotReturnDisabled.value = true;
      } catch (error) {
        console.error(error);
      }
    };

    const loadSlotGone = async (selectedDate) => {
      const mergedSlotsGone = await mergeSlotsGone(
        timeSlotsGone.value,
        selectedDate
      );
      timeSlotsGone.value.splice(
        0,
        timeSlotsGone.value.length,
        ...mergedSlotsGone
      );
    };

    const loadSlotReturn = async (selectedDate) => {
      const mergedSlotsReturn = await mergeSlotsReturn(
        timeSlotsReturn.value,
        selectedDate
      );
      timeSlotsReturn.value.splice(
        0,
        timeSlotsReturn.value.length,
        ...mergedSlotsReturn
      );
    };

    const mergeSlotsGone = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotGone(selectedDate);

      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          const isAvailable = availableSlots.includes(timeSlot.value);

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const mergeSlotsReturn = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotReturn(selectedDate);

      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          let isAvailable = availableSlots.includes(timeSlot.value);

          const [hour, minute] = timeSlot.value.split(':').map(Number);

          const newDate = dayjs(selectedDate)
            .hour(hour)
            .minute(minute)
            .second(0)
            .toDate();

          isAvailable =
            isAvailable && !slotGoneDisabled.value
              ? new Date(newDate).getTime() >
                new Date(date_gone.value).getTime()
              : isAvailable;

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    let loadingSlotsGone = ref(true);
    let request_slot_gone = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotGone = async (date) => {
      loadingSlotsGone.value = true;
      try {
        request_slot_gone.originPoint = toRaw(origin_point_gone);
        request_slot_gone.destinationPoint = toRaw(destination_point_gone);
        request_slot_gone.partnerDay = date;
        request_slot_gone.type = type_prenotazione_gone;
        request_slot_gone.area = area.value;

        let response = null;
        response = await prenotazioneAPI.calcSlot(request_slot_gone);

        if (response) {
          loadingSlotsGone.value = false;
        }
        return response.data;
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    let loadingSlotsReturn = ref(true);
    let request_slot_return = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotReturn = async (date) => {
      loadingSlotsReturn.value = true;
      try {
        request_slot_return.originPoint = origin_point_return;
        request_slot_return.destinationPoint = destination_point_return;
        request_slot_return.type = type_prenotazione_return;
        request_slot_return.partnerDay = date;
        request_slot_return.area = area.value;

        let response_slot = await prenotazioneAPI.calcSlot(request_slot_return);

        loadingSlotsReturn.value = false;
        return response_slot.data;
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    const extractBookingDetail = (
      date,
      isGone,
      id,
      driver,
      originPoint,
      destinationPoint,
      type
    ) => {
      if (isGone) {
        date_gone.value = date;
        is_gone.value = true;
        id_prenotazione_gone = id;
        driver_gone = driver;
        origin_point_gone = originPoint;
        destination_point_gone = destinationPoint;
        type_prenotazione_gone = type;
      } else {
        date_return.value = date;
        is_return.value = true;
        id_prenotazione_return = id;
        driver_return = driver;
        origin_point_return = originPoint;
        destination_point_return = destinationPoint;
        type_prenotazione_return = type;
      }
    };

    const onSubmitDate = async () => {
      if (slotGoneDisabled.value && slotReturnDisabled.value) {
        modifyPrenotazione(
          new_date_gone.value,
          id_prenotazione_gone,
          booking_info.loaner == true ? 'AUTO_GONE' : 'GONE',
          origin_point_gone,
          destination_point_gone
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyPrenotazione(
          new_date_return.value,
          id_prenotazione_return,
          booking_info.loaner == true ? 'AUTO_RETURN' : 'RETURN',
          origin_point_return,
          destination_point_return
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(
          new_date_gone.value,
          new_date_return.value,
          route.params.id,
          'both'
        ).then(async (response) => {
          if (response.status === 200) {
            console.log('data modificata!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });
      } else if (slotReturnDisabled.value && !slotGoneDisabled.value) {
        modifyPrenotazione(
          new_date_return.value,
          id_prenotazione_return,
          booking_info.loaner == true ? 'AUTO_RETURN' : 'RETURN',
          origin_point_return,
          destination_point_return
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });
            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(
          null,
          new_date_return.value,
          route.params.id,
          'return'
        ).then(async (response) => {
          if (response.status === 200) {
            console.log('data modificata!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });
      } else if (!slotReturnDisabled.value && slotGoneDisabled.value) {
        modifyPrenotazione(
          new_date_gone.value,
          id_prenotazione_gone,
          booking_info.loaner == true ? 'AUTO_GONE' : 'GONE',
          origin_point_gone,
          destination_point_gone
        ).then(async (response) => {
          if (response.status === 201) {
            ElMessage({
              message: 'Booking modificato!',
              type: 'success',
            });

            console.log('Booking aggiunto!');
          } else {
            ElMessage({
              message: 'Errore modifica booking!',
              type: 'error',
            });
            console.log('errore inatteso');
          }
        });

        modifyBooking(new_date_gone.value, null, route.params.id, 'gone').then(
          async (response) => {
            if (response.status === 200) {
              console.log('data modificata!');
            } else {
              ElMessage({
                message: 'Errore modifica booking!',
                type: 'error',
              });
              console.log('errore inatteso');
            }
          }
        );
      }
    };

    const modifyPrenotazione = async (
      new_date,
      id_prenotazione,
      type_prenotazione,
      origin_point,
      destination_point
    ) => {
      let response;
      try {
        switch (type.value) {
          case 1:
            response = await bookingAPI.updateDatePickup(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'PICKUP_' + type_prenotazione,
              area: area.value,
            });
            return response;
          case 2:
            response = await bookingAPI.updateDateEscort(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'ESCORT_' + type_prenotazione,
              area: area.value,
            });
            return response;
          case 3:
            response = await bookingAPI.updateDateDelivery(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'DELIVERY_' + type_prenotazione,
              area: area.value,
            });
            return response;

          case 4:
            response = await bookingAPI.updateDateEscort(id_prenotazione, {
              originPoint: origin_point,
              destinationPoint: destination_point,
              partnerTime: new_date,
              type: 'ESCORT_' + type_prenotazione,
              area: area.value,
            });
            return response;
        }
      } catch (e) {
        console.log(e);
      }
    };

    const modifyBooking = async (
      new_date_gone,
      new_date_return,
      id_booking,
      type
    ) => {
      let response;

      let data = {
        customer: booking.value.customer,
        booking: booking.value,
        type: type, //"gone", "return", "both"
        goneDriver: driver_gone,
        returnDriver: driver_return,
        newDateGone: new_date_gone,
        newDateReturn: new_date_return,
      };
      try {
        let new_date;

        if (is_gone.value && new_date_gone) new_date = new_date_gone;
        if (is_gone.value && !new_date_gone) new_date = date_gone.value;
        if (!is_gone.value && new_date_return) new_date = new_date_return;

        response = await bookingAPI.updateDateBooking(id_booking, {
          data: new_date,
        });

        await bookingAPI.messageModifyHourBooking(data);
        return response;
      } catch (e) {
        console.log(e);
      }
    };

    const handleSlotChangeGone = (value) => {
      new_date_gone.value = dayjs(new Date(new_date_gone.value))
        .hour(value[0].split(':')[0])
        .minute(value[0].split(':')[1])
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss');
    };

    const handleSlotChangeReturn = (value) => {
      new_date_return.value = dayjs(new Date(new_date_return.value))
        .hour(value[0].split(':')[0])
        .minute(value[0].split(':')[1])
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss');
    };

    return {
      type,
      bookingId,
      booking_info,
      date_gone,
      date_return,
      loadBooking,
      postDriverAssign,
      patchBookingState,
      activeName,
      open,
      hour_gone,
      hour_return,
      plannerToUpd,
      bookingStateToUpd,
      is_gone,
      is_return,
      noteVisibile,
      ruoloSwitch,
      planners,
      driverVisible,
      driverSelected,
      loadOptionsState,
      options,
      new_date_gone,
      new_date_return,
      handleChangeDateGone,
      handleChangeDateReturn,
      selectedSlotGone,
      selectedSlotReturn,
      timeSlotsGone,
      timeSlotsReturn,
      handleSlotChangeGone,
      handleSlotChangeReturn,
      onSubmitDate,
      loadSlotGone,
      slotGoneDisabled,
      slotReturnDisabled,
    };
  },

  methods: {
    onSubmitDriver(id) {
      const pl = this.planners.find((f) => f.plannerId === id);
      this.plannerToUpd = {
        data_fine: pl.data_fine,
        data_inizio: pl.data_inizio,
        itinerario: pl.itinerario,
        plannerId: pl.plannerId,
        driverId: pl.driverId,
        raceId: pl.raceId,
        type: pl.type == true ? 'driver' : 'jolly',
      };

      this.postDriverAssign(toRaw(this.plannerToUpd)).then((response) => {
        if (response.status === 201) {
          //this.$router.go("/booking");
          ElMessage({
            message: 'Driver aggiornato!',
            type: 'success',
          });
          console.log('Driver aggiornato!');
        } else {
          console.log('errore inatteso');
        }
      });
    },

    onSubmitBooking(booking) {
      this.bookingStateToUpd = {
        id: booking.id,
        state: booking.state,
      };

      this.patchBookingState(toRaw(this.bookingStateToUpd)).then((response) => {
        if (response.status === 200) {
          this.$router.go('/booking');
          ElMessage({
            message: 'Stato del booking aggiornato!',
            type: 'success',
          });
          console.log('Stato del booking aggiornato!');
        } else {
          console.log('errore inatteso');
        }
      });

      if (
        (this.slotGoneDisabled && this.selectedSlotGone) ||
        (this.slotReturnDisabled && this.selectedSlotReturn)
      ) {
        this.onSubmitDate();
      }
    },
  },
};
</script>

<style>
.margin {
  margin-top: 30px;
}

hr {
  margin-top: 20px;
}

.marg_form {
  margin-top: 100px;
}

.el-input-group__append {
  background: transparent;
}

.collapse-title {
  font-size: 18px;
  font-weight: bold;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }

  200% {
    transform: scale(1);
  }
}
</style>
